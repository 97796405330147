<template>
  <v-layout v-if="_.size(detail) > 0" row wrap>
    <v-flex xs12 lg12 md12>
      <v-alert v-if="!detail.status.box_color" :value="true" color="info">
        Recuerde que debe activar el modo "Venta por seleccion de color" en el panel de estado. Para utilizar este
        metodo de compra.
        <router-link
          :key="index"
          :to="{
            name: 'productStatus',
            params: { id: detail.id }
          }"
        >
          (Activar "Venta por seleccion de color")
        </router-link>
      </v-alert>
      <v-card>
        <titleCard title="Colores" subtitle="Listado de colores asociados a este producto"> </titleCard>
        <v-divider></v-divider>
        <v-data-table
          :items="detail.colors"
          class="elevation-1"
          no-data-text="No hay atributos ingresados"
          :headers="[
            { text: 'Color', align: 'left', sortable: false },
            { text: 'Precio por defecto', align: 'left', sortable: false },
            { text: 'Precio normal', align: 'right', sortable: false },
            { text: 'Precio alta demanda', align: 'right', sortable: false },
            { text: '', align: 'left', sortable: false }
          ]"
          hide-actions
        >
          <template v-slot:items="props">
            <td v-html="props.item.name"></td>
            <td>
              <v-chip v-if="props.item.price_default" color="success" text-color="white"> Activo </v-chip>
              <v-chip v-else color="error" text-color="white"> Inactivo </v-chip>
            </td>
            <td class="text-xs-right">
              {{ props.item.price | toPrice }}
            </td>
            <td class="text-xs-right">
              {{ props.item.price_high | toPrice }}
            </td>
            <td>
              <v-icon small @click="editColor(props.item)"> edit </v-icon>
              <v-icon small @click="deleteColor(props.item.id)"> delete </v-icon>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <v-btn fixed dark fab bottom right color="accent" @click="openColor">
      <v-icon> add </v-icon>
    </v-btn>
    <v-dialog v-model="dialogColor" persistent max-width="300px">
      <v-card>
        <titleDialog :title="'Añadir Color'" close :close-action="() => (dialogColor = false)"></titleDialog>
        <v-divider></v-divider>
        <v-card-text v-if="colors_list">
          <v-select
            v-model="id_color"
            :items="filterColors"
            item-value="id"
            :item-text="e => `${e.name} (${e.status ? 'Activo' : 'Inactivo'})`"
            label="Colores disponibles"
          ></v-select>
          <template v-if="id_color">
            <v-text-field
              v-model="price_normal"
              label="Precio Normal"
              type="number"
              prepend-icon="attach_money"
              :disabled="price_default"
            ></v-text-field>

            <v-text-field
              v-model="price_high"
              label="Precio alta demanda"
              type="number"
              prepend-icon="attach_money"
              :disabled="price_default"
            ></v-text-field>

            <v-switch v-model="price_default" label="Usar precios del color seleccionado" hide-details></v-switch>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="back" flat @click.native="dialogColor = false">Volver</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click.native="saveColor">Listo</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { GET_COLORS_CAJA, GET_PRODUCT } from '../../config'
import titleCard from '../useful/titleCard.vue'
import titleDialog from '../useful/titleDialog.vue'

export default {
  name: 'ProductAttributes',
  components: { titleCard, titleDialog },
  props: ['product', 'getProduct'],
  data: () => ({
    id: null,
    id_color: null,
    price_normal: null,
    price_high: null,
    price_default: false,
    attributeProduct: {
      attribute: {},
      selected: {},
      id: 0
    },
    colors: [],
    colors_list: [],
    attributes: [],
    dialogColor: false,
    loadingBtn: false,
    loading: false,
    active: true,
    stock: true,
    scheduleRetirement: 0,
    detail: {},
    states: [
      { name: 'Florida', abbr: 'FL', id: 1 },
      { name: 'Georgia', abbr: 'GA', id: 2 },
      { name: 'Nebraska', abbr: 'NE', id: 3 },
      { name: 'California', abbr: 'CA', id: 4 },
      { name: 'New York', abbr: 'NY', id: 5 }
    ]
  }),
  computed: {
    filterColors() {
      const colors = []
      const colors_ = []
      this.detail.colors.forEach(e => {
        colors.push(e.id_box_color)
      })
      if (this.id) {
        const index = colors.indexOf(this.id_color)
        if (index > -1) {
          colors.splice(index, 1) // 2nd parameter means remove one item only
        }
      }
      console.log(colors)
      this.colors_list.forEach(e => {
        const key = this._.findIndex(colors, function(o) {
          return o == e.id
        })
        if (key == -1) {
          colors_.push(e)
        }
      })
      return colors_
    }
  },
  watch: {
    product() {
      this.init()
    },
    price_default(e) {
      if (e) {
        console.log(this.id_color)
        if (this.id_color) {
          const color = this._.filter(this.colors_list, ['id', this.id_color])[0]
          this.price_normal = color.price
          this.price_high = color.price_high
        }
      }
    },
    dialogColor(e) {
      if (!e) {
        this.id = null
        this.id_color = null
        this.price_normal = null
        this.price_high = null
        this.price_default = false
      }
    }
  },
  mounted() {
    this.init()
    this.getColors()
  },
  methods: {
    async getColors() {
      try {
        const res = await this.$http.get(GET_COLORS_CAJA)
        this.colors_list = res.data.colors
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async saveColor() {
      const { id_color, price_normal, price_high, price_default, id } = this
      if (!id_color || !price_normal || !price_high) {
        return
      }
      try {
        const params = {
          id_color,
          price_normal,
          price_high,
          price_default
        }
        let res
        if (id > 0) {
          res = await this.$http.put(`${GET_PRODUCT}/${this.product.id}/colors/${id}`, params)
        } else {
          res = await this.$http.post(`${GET_PRODUCT}/${this.product.id}/colors`, params)
        }

        if (!res.data.error) {
          this.getProduct()
          this.dialogColor = false
        }
        this.$store.dispatch('changeSnack', {
          active: true,
          text: res.data.msj
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async deleteColor(id) {
      try {
        const res = await this.$http.delete(`${GET_PRODUCT}/${this.product.id}/colors/${id}`)
        if (!res.data.error) {
          this.getProduct()
          this.dialogColor = false
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async changeStatusAttribute(id, status) {
      try {
        await this.$http.put(`${GET_PRODUCT}/${this.product.id}/attribute/${id}/status`, {
          status
        })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Actualizado'
        })
        this.getProduct()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    editColor(item) {
      this.id = item.id
      this.id_color = item.id_box_color
      this.price_normal = item.price
      this.price_high = item.price_high
      this.price_default = item.price_default === 1
      this.openColor()
    },
    openColor() {
      this.dialogColor = true
    },
    setAttributes() {
      if (this._.size(this.detail) > 0) {
        const attributes = []
        this.detail.attributes.forEach(element => {
          const result = this._.find(this.attributes, ['id', element.idgrupo_atributos])
          const selected = this._.find(result.atributos, ['id', element.defecto])
          attributes.push({
            attribute: result,
            selected,
            status: element.status,
            id: element.id
          })
        })
        this.colors = attributes
      }
    },
    init() {
      this.detail = this.product
      // this.getOrdersProduct()
    }
  }
}
</script>
